<template>
  <div>
    <s-dialog :open="true" size="sm" :to="`stds-dialog-delete-discount`">
      <s-dialog-overlay />
      <s-dialog-panel>
        <s-dialog-content>
          <s-dialog-content-body>
            <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">
              {{ $t('studio.common.live_apply_btn') }}
            </p>
            <p class="mt-4 text-xs leading-xs font-regular text-on-surface-elevation-4">
              <safe-html :html="$t('studio.common.popup_case_i_release_guide')" />
            </p>
            <div class="mt-8">
              <input-text
                size="lg"
                variant="outline"
                :placeholder="$t('studio.common.popup_case_i_release_place_holder')"
                name="confirmInputText"
                inputClass="uppercase"
              />
            </div>
          </s-dialog-content-body>
          <s-dialog-footer class="w-full px-0">
            <s-button class="w-full" variant="outline" @click="onClose">
              {{ $t('studio.common.popup_case_cancel_btn') }}
            </s-button>
            <s-button
              class="w-full"
              variant="primary"
              :disabled="values.confirmInputText.toUpperCase() !== RELEASE_TEXT"
              @click="onSubmit"
            >
              {{ $t('studio.common.popup_case_apply_btn') }}
            </s-button>
          </s-dialog-footer>
        </s-dialog-content>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="`stds-dialog-delete-discount`" />
  </div>
</template>
<script setup lang="ts">
import { useForm } from 'vee-validate';

import SafeHtml from '@/components/common/safe-html.vue';
import InputText from '@/components/validation/input-text.vue';

const RELEASE_TEXT = 'RELEASE';

const { handleSubmit, values } = useForm({
  initialValues: {
    confirmInputText: ''
  }
});

const emits = defineEmits<{
  close: [v: boolean];
}>();

const onSubmit = handleSubmit(() => {
  emits('close', true);
});

const onClose = () => {
  emits('close', false);
};
</script>
